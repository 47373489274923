<script setup lang="ts">
const props = defineProps<{
  name?: string
  url?: string
  iconUrl?: string
}>()

const icon = computed(() => `${props.iconUrl}`)
</script>

<template>
  <a :href="props.url" target="_blank">
    <button class="flex gap-2 rounded-3xl bg-mcd-secondary px-6 py-3">
      <img :src="icon" :alt="props.name" />
      {{ props.name }}
    </button>
  </a>
</template>
